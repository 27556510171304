import { Board, Task } from "../types";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";

interface ProjectMetricsProps {
  board: Board;
}

export default function ProjectMetrics({ board }: ProjectMetricsProps) {
  const calculateMetrics = () => {
    let totalTasks = 0;
    let completedTasks = 0;
    let inProgressTasks = 0;
    let upcomingTasks = 0;
    let overdueTasks = 0;
    const teamCapacity: { [key: string]: number } = {};

    // Asumimos que la última columna es "Done" o similar
    const lastColumnId = board.columnOrder[board.columnOrder.length - 1];
    const today = new Date();

    Object.values(board.columns).forEach((column) => {
      totalTasks += column.tasks.length;

      column.tasks.forEach((task: Task) => {
        // Track team capacity
        if (task.assignee) {
          teamCapacity[task.assignee] = (teamCapacity[task.assignee] || 0) + 1;
        }

        if (column.id === lastColumnId) {
          completedTasks++;
        } else if (task.startDate && new Date(task.startDate) <= today) {
          inProgressTasks++;
        } else {
          upcomingTasks++;
        }

        if (
          task.dueDate &&
          new Date(task.dueDate) < today &&
          column.id !== lastColumnId
        ) {
          overdueTasks++;
        }
      });
    });

    return {
      totalTasks,
      completedTasks,
      inProgressTasks,
      upcomingTasks,
      overdueTasks,
      teamCapacity,
      completionRate: totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0,
    };
  };

  const metrics = calculateMetrics();

  return (
    <div className="space-y-4">
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">
              Progreso Total
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold mb-2">
              {metrics.completionRate.toFixed(1)}%
            </div>
            <Progress value={metrics.completionRate} className="h-2" />
            <CardDescription className="mt-2">
              {metrics.completedTasks} de {metrics.totalTasks} tareas
              completadas
            </CardDescription>
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">En Progreso</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{metrics.inProgressTasks}</div>
            <CardDescription>tareas activas</CardDescription>
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Próximas</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{metrics.upcomingTasks}</div>
            <CardDescription>tareas por comenzar</CardDescription>
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Atrasadas</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold text-red-500">
              {metrics.overdueTasks}
            </div>
            <CardDescription>tareas vencidas</CardDescription>
          </CardContent>
        </Card>
      </div>

      {/* Team Capacity Section */}
      {Object.keys(metrics.teamCapacity).length > 0 && (
        <Card>
          <CardHeader>
            <CardTitle>Capacidad del Equipo</CardTitle>
            <CardDescription>
              Distribución de tareas por miembro del equipo
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              {Object.entries(metrics.teamCapacity).map(
                ([member, taskCount]) => (
                  <div key={member} className="space-y-2">
                    <div className="flex justify-between text-sm">
                      <span>{member}</span>
                      <span className="font-medium">{taskCount} tareas</span>
                    </div>
                    <Progress
                      value={(taskCount / metrics.totalTasks) * 100}
                      className="h-2"
                    />
                  </div>
                )
              )}
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
}
