import type React from "react";
import { useState, useRef, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  Send,
  Paperclip,
  Mic,
  File,
  Hash,
  ChevronDown,
  Bold,
  Italic,
  List,
  Link,
} from "lucide-react";
import Navbar from "@/components/narbar/narbar";
import { useTranslation } from "react-i18next";

interface Channel {
  id: string;
  name: string;
  unreadCount: number;
}

interface DirectMessage {
  id: string;
  name: string;
  avatar: string;
  isOnline: boolean;
  unreadCount: number;
}

interface Message {
  id: string;
  sender: string;
  timestamp: Date;
  content: string;
  type: "text" | "file" | "audio";
  fileUrl?: string;
}

const initialChannels: Channel[] = [
  { id: "1", name: "general", unreadCount: 2 },
  { id: "2", name: "random", unreadCount: 1 },
];

const initialDirectMessages: DirectMessage[] = [
  {
    id: "1",
    name: "María García",
    avatar: "/avatar2.png",
    isOnline: false,
    unreadCount: 0,
  },
  {
    id: "2",
    name: "Juan Pérez",
    avatar: "/avatar1.png",
    isOnline: true,
    unreadCount: 1,
  },
];

const initialMessages: Message[] = [
  {
    id: "1",
    sender: "Tú",
    timestamp: new Date(),
    content: "Hola, ¿qué tal?",
    type: "text",
    fileUrl: undefined,
  },
  {
    id: "2",
    sender: "María García",
    timestamp: new Date(),
    content: "Bien, gracias. ¿Y tú?",
    type: "text",
    fileUrl: undefined,
  },
];

const ChatView = () => {
  const { t } = useTranslation();
  const [channels, setChannels] = useState(initialChannels);
  const [directMessages, setDirectMessages] = useState(initialDirectMessages);
  const [selectedChannel, setSelectedChannel] = useState<Channel | null>(null);
  const [selectedDirectMessage, setSelectedDirectMessage] =
    useState<DirectMessage | null>(null);
  const [messages, setMessages] = useState(initialMessages);
  const [newMessage, setNewMessage] = useState("");
  /*   const [showConversations, setShowConversations] = useState(true);
   */ const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleSendMessage = () => {
    if (newMessage.trim() === "") return;
    setMessages([
      ...messages,
      {
        id: Date.now().toString(),
        sender: "Tú",
        timestamp: new Date(),
        content: newMessage,
        type: "text",
      },
    ]);
    setNewMessage("");
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      console.log("File uploaded:", file.name);
    }
  };

  const handleChannelClick = (channel: Channel) => {
    setSelectedChannel(channel);
    setSelectedDirectMessage(null);
    setChannels((prevChannels) =>
      prevChannels.map((ch) =>
        ch.id === channel.id ? { ...ch, unreadCount: 0 } : ch
      )
    );
    setMessages(initialMessages);
  };

  const handleDirectMessageClick = (dm: DirectMessage) => {
    setSelectedDirectMessage(dm);
    setSelectedChannel(null);
    setDirectMessages((prevDMs) =>
      prevDMs.map((d) => (d.id === dm.id ? { ...d, unreadCount: 0 } : d))
    );
    setMessages(initialMessages);
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <div className="flex flex-1 bg-white">
        {/* Sidebar */}
        <div className="w-64 bg-[#3F0E40] text-white flex flex-col">
          {/* Workspace name */}
          <div className="p-4 border-b border-[#522653] flex items-center justify-between">
            <h1 className="font-bold text-lg">Workspace Name</h1>
            <ChevronDown className="h-5 w-5" />
          </div>

          {/* Channels */}
          <div className="p-4 overflow-y-auto">
            <h2 className="text-lg font-semibold mb-4">
              {t("common.channels")}
            </h2>
            <ul>
              {channels.map((channel) => (
                <li
                  key={channel.id}
                  className={`flex items-center mb-2 cursor-pointer p-2 rounded ${
                    selectedChannel?.id === channel.id
                      ? "bg-[#1164A3]"
                      : "hover:bg-[#350D36]"
                  }`}
                  onClick={() => handleChannelClick(channel)}
                >
                  <Hash className="h-4 w-4 mr-2" />
                  <span>{channel.name}</span>
                  {channel.unreadCount > 0 && (
                    <span className="ml-auto bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                      {channel.unreadCount}
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </div>

          {/* Direct Messages */}
          <div className="p-4 overflow-y-auto">
            <h2 className="text-lg font-semibold mb-4">
              {t("common.directMessages")}
            </h2>
            <ul>
              {directMessages.map((dm) => (
                <li
                  key={dm.id}
                  className={`flex items-center mb-2 cursor-pointer p-2 rounded ${
                    selectedDirectMessage?.id === dm.id
                      ? "bg-[#1164A3]"
                      : "hover:bg-[#350D36]"
                  }`}
                  onClick={() => handleDirectMessageClick(dm)}
                >
                  <div className="relative mr-2">
                    <Avatar className="h-6 w-6">
                      <AvatarImage src={dm.avatar} alt={dm.name} />
                      <AvatarFallback>{dm.name.charAt(0)}</AvatarFallback>
                    </Avatar>
                    {dm.isOnline && (
                      <span className="absolute bottom-0 right-0 block h-2 w-2 rounded-full bg-green-400 ring-1 ring-white"></span>
                    )}
                  </div>
                  <span>{dm.name}</span>
                  {dm.unreadCount > 0 && (
                    <span className="ml-auto bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                      {dm.unreadCount}
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="flex-1 flex flex-col">
          {(selectedChannel || selectedDirectMessage) && (
            <div className="border-b p-4 flex items-center">
              <h2 className="text-xl font-semibold flex items-center">
                {selectedChannel ? (
                  <>
                    <Hash className="h-5 w-5 mr-2" />
                    {selectedChannel.name}
                  </>
                ) : (
                  <>
                    <div className="relative mr-2">
                      <Avatar className="h-6 w-6">
                        <AvatarImage
                          src={selectedDirectMessage?.avatar}
                          alt={selectedDirectMessage?.name}
                        />
                        <AvatarFallback>
                          {selectedDirectMessage?.name.charAt(0)}
                        </AvatarFallback>
                      </Avatar>
                      {selectedDirectMessage?.isOnline && (
                        <span className="absolute bottom-0 right-0 block h-2 w-2 rounded-full bg-green-400 ring-1 ring-white"></span>
                      )}
                    </div>
                    {selectedDirectMessage?.name}
                  </>
                )}
              </h2>
            </div>
          )}

          <ScrollArea className="flex-1 p-4">
            {messages.map((message) => (
              <div key={message.id} className="mb-4 flex">
                <Avatar className="h-10 w-10 mr-4">
                  <AvatarImage
                    src={
                      message.sender === "Tú"
                        ? "/your-avatar.png"
                        : selectedDirectMessage?.avatar || "/avatar1.png"
                    }
                    alt={message.sender}
                  />
                  <AvatarFallback>{message.sender.charAt(0)}</AvatarFallback>
                </Avatar>
                <div>
                  <div className="flex items-baseline">
                    <span className="font-bold mr-2">{message.sender}</span>
                    <span className="text-xs text-gray-500">
                      {message.timestamp.toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </span>
                  </div>
                  <div className="mt-1">
                    {message.type === "text" && <p>{message.content}</p>}
                    {message.type === "file" && (
                      <div className="flex items-center bg-gray-100 p-2 rounded">
                        <File className="mr-2 h-4 w-4" />
                        <a
                          href={message.fileUrl}
                          download
                          className="text-blue-500 hover:underline"
                        >
                          {message.content}
                        </a>
                      </div>
                    )}
                    {message.type === "audio" && (
                      <div className="flex items-center bg-gray-100 p-2 rounded">
                        <Mic className="mr-2 h-4 w-4" />
                        <audio
                          src={message.fileUrl}
                          controls
                          className="w-full"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </ScrollArea>

          <div className="border-t p-4">
            <div className="flex flex-col space-y-2">
              <div className="flex items-center space-x-2">
                <Button variant="outline" size="icon">
                  <Bold className="h-4 w-4" />
                </Button>
                <Button variant="outline" size="icon">
                  <Italic className="h-4 w-4" />
                </Button>
                <Button variant="outline" size="icon">
                  <List className="h-4 w-4" />
                </Button>
                <Button variant="outline" size="icon">
                  <Link className="h-4 w-4" />
                </Button>
              </div>
              <div className="flex space-x-2">
                <Input
                  type="text"
                  placeholder={t("chat.messageInput")}
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  className="flex-1"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSendMessage();
                    }
                  }}
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  onChange={handleFileUpload}
                />
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => fileInputRef.current?.click()}
                  title={t("chat.fileUpload")}
                >
                  <Paperclip className="h-4 w-4" />
                </Button>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => console.log("Audio record button clicked")}
                  title={t("chat.audioRecord")}
                >
                  <Mic className="h-4 w-4" />
                </Button>
                <Button
                  onClick={handleSendMessage}
                  title={t("chat.sendMessage")}
                >
                  <Send className="h-4 w-4" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatView;
