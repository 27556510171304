import { useState } from "react";
import {
  ChevronDown,
  ChevronRight,
  Plus,
  Search,
  SlidersHorizontal,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Badge } from "@/components/ui/badge";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Board } from "../types";

interface TableViewProps {
  board: Board;
}

export default function TableView({ board }: TableViewProps) {
  const [isExpanded, setIsExpanded] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  // Extraer todas las tareas de todas las columnas
  const allTasks = Object.values(board.columns).flatMap((column) =>
    column.tasks.map((task) => ({
      ...task,
      status: column.title,
    }))
  );

  const filteredTasks = allTasks.filter(
    (task) =>
      task.content.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (task.assignee?.toLowerCase() || "").includes(searchQuery.toLowerCase())
  );

  return (
    <div className="flex flex-col h-full">
      {/* Barra de filtros */}
      <div className="flex items-center gap-2 p-4 border-b">
        <div className="flex-1 flex items-center gap-2">
          <Search className="w-4 h-4 text-muted-foreground" />
          <Input
            placeholder="Filter by keyword or by field"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="max-w-md"
          />
        </div>
        <Button variant="outline" size="sm">
          <SlidersHorizontal className="w-4 h-4 mr-2" />
          Filters
        </Button>
        <Button size="sm">
          <Plus className="w-4 h-4 mr-2" />
          Add item
        </Button>
      </div>

      {/* Tabla */}
      <div className="flex-1 overflow-auto">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[50%]">Title</TableHead>
              <TableHead>Assignee</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Tags</TableHead>
              <TableHead>Dates</TableHead>
              <TableHead className="w-[70px]"></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {/* Grupo No Priority */}
            <TableRow className="group hover:bg-muted/50">
              <TableCell colSpan={6}>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setIsExpanded(!isExpanded)}
                  className="h-6 px-2"
                >
                  {isExpanded ? (
                    <ChevronDown className="w-4 h-4 mr-2" />
                  ) : (
                    <ChevronRight className="w-4 h-4 mr-2" />
                  )}
                  No Priority
                  <Badge variant="secondary" className="ml-2">
                    {filteredTasks.length}
                  </Badge>
                </Button>
              </TableCell>
            </TableRow>
            {isExpanded &&
              filteredTasks.map((task) => (
                <TableRow key={task.id} className="group">
                  <TableCell className="font-medium">{task.content}</TableCell>
                  <TableCell>
                    <div className="flex items-center gap-2">
                      <Avatar className="h-6 w-6">
                        <AvatarFallback>
                          {task.assignee?.charAt(0).toUpperCase()}
                        </AvatarFallback>
                      </Avatar>
                      {task.assignee}
                    </div>
                  </TableCell>
                  <TableCell>
                    <Badge variant="secondary">{task.status}</Badge>
                  </TableCell>
                  <TableCell>
                    <div className="flex gap-1">
                      {(task.tags || []).map((tag) => (
                        <Badge
                          key={tag.id}
                          style={{
                            backgroundColor: tag.color,
                            color: "white",
                          }}
                        >
                          {tag.name}
                        </Badge>
                      ))}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="text-sm text-muted-foreground">
                      {task.startDate
                        ? new Date(task.startDate).toLocaleDateString()
                        : "No start date"}{" "}
                      -
                      {task.endDate
                        ? new Date(task.endDate).toLocaleDateString()
                        : "No end date"}
                    </div>
                  </TableCell>
                  <TableCell>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          variant="ghost"
                          size="sm"
                          className="h-8 w-8 p-0 opacity-0 group-hover:opacity-100"
                        >
                          <span className="sr-only">Open menu</span>
                          <SlidersHorizontal className="h-4 w-4" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end">
                        <DropdownMenuItem>Edit</DropdownMenuItem>
                        <DropdownMenuItem className="text-destructive">
                          Delete
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
