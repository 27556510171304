import { Board } from "./types";

export const initialBoards: Board[] = [
  {
    id: "board-1",
    title: "Proyecto Principal",
    description: "Proyecto inicial de ejemplo",
    type: "team",
    columns: {
      "column-1": {
        id: "column-1",
        title: "Por hacer",
        tasks: [
          {
            id: "task-1",
            content: "Crear diseño de la aplicación",
            assignedTo: ["Ana"],
            dueDate: "2023-06-07",
            priority: "high",
            labels: ["Diseño"]
          },
          {
            id: "task-2",
            content: "Implementar autenticación",
            assignedTo: ["Carlos"],
            dueDate: "2023-06-14",
            priority: "medium",
            labels: ["Backend"]
          },
        ],
      },
      "column-2": {
        id: "column-2",
        title: "En progreso",
        tasks: [
          {
            id: "task-3",
            content: "Desarrollar API REST",
            assignedTo: ["María"],
            dueDate: "2023-06-21",
            priority: "high",
            labels: ["Backend"]
          },
        ],
      },
      "column-3": {
        id: "column-3",
        title: "Completado",
        tasks: [],
      },
    },
    columnOrder: ["column-1", "column-2", "column-3"],
  },
];
