import { Board, Task } from "../types";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line, Doughnut, Bar } from "react-chartjs-2";
import { Card } from "@/components/ui/card";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

interface ProjectChartsProps {
  board: Board;
}

export default function ProjectCharts({ board }: ProjectChartsProps) {
  // Calcular datos para el gráfico de progreso por día
  const calculateDailyProgress = () => {
    const tasksByDate = new Map<string, { total: number; completed: number }>();
    const lastColumnId = board.columnOrder[board.columnOrder.length - 1];
    
    Object.values(board.columns).forEach((column) => {
      column.tasks.forEach((task: Task) => {
        if (task.startDate) {
          const date = new Date(task.startDate).toLocaleDateString();
          const current = tasksByDate.get(date) || { total: 0, completed: 0 };
          current.total++;
          if (column.id === lastColumnId) {
            current.completed++;
          }
          tasksByDate.set(date, current);
        }
      });
    });

    const sortedDates = Array.from(tasksByDate.keys()).sort();
    return {
      labels: sortedDates,
      datasets: [
        {
          label: "Tareas Completadas",
          data: sortedDates.map(date => tasksByDate.get(date)?.completed || 0),
          borderColor: "rgb(75, 192, 192)",
          tension: 0.4,
          fill: true,
          backgroundColor: "rgba(75, 192, 192, 0.2)",
        },
        {
          label: "Total Tareas",
          data: sortedDates.map(date => tasksByDate.get(date)?.total || 0),
          borderColor: "rgb(53, 162, 235)",
          tension: 0.4,
          fill: true,
          backgroundColor: "rgba(53, 162, 235, 0.2)",
        },
      ],
    };
  };

  // Calcular datos para el gráfico de distribución de tareas
  const calculateTaskDistribution = () => {
    const distribution = {
      labels: [] as string[],
      data: [] as number[],
      backgroundColor: [] as string[],
    };

    Object.values(board.columns).forEach((column) => {
      distribution.labels.push(column.title);
      distribution.data.push(column.tasks.length);
      distribution.backgroundColor.push(
        `hsl(${Math.random() * 360}, 70%, 50%)`
      );
    });

    return {
      labels: distribution.labels,
      datasets: [
        {
          data: distribution.data,
          backgroundColor: distribution.backgroundColor,
          borderWidth: 1,
        },
      ],
    };
  };

  // Calcular datos para el gráfico de prioridades
  const calculatePriorityDistribution = () => {
    const priorities = {
      high: 0,
      medium: 0,
      low: 0,
      none: 0,
    };

    Object.values(board.columns).forEach((column) => {
      column.tasks.forEach((task: Task) => {
        if (task.priority) {
          priorities[task.priority]++;
        } else {
          priorities.none++;
        }
      });
    });

    return {
      labels: ["Alta", "Media", "Baja", "Sin prioridad"],
      datasets: [
        {
          label: "Tareas por Prioridad",
          data: [priorities.high, priorities.medium, priorities.low, priorities.none],
          backgroundColor: [
            "rgba(255, 99, 132, 0.8)",
            "rgba(255, 206, 86, 0.8)",
            "rgba(75, 192, 192, 0.8)",
            "rgba(201, 203, 207, 0.8)",
          ],
        },
      ],
    };
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    maintainAspectRatio: false,
  };

  const doughnutOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className="grid gap-6 mt-6">
      {/* Gráfico de Progreso */}
      <Card className="p-6">
        <h3 className="text-lg font-semibold mb-4">Progreso del Proyecto</h3>
        <div className="h-[300px]">
          <Line options={options} data={calculateDailyProgress()} />
        </div>
      </Card>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Distribución de Tareas por Columna */}
        <Card className="p-6">
          <h3 className="text-lg font-semibold mb-4">Distribución de Tareas</h3>
          <div className="h-[300px]">
            <Doughnut options={doughnutOptions} data={calculateTaskDistribution()} />
          </div>
        </Card>

        {/* Distribución por Prioridad */}
        <Card className="p-6">
          <h3 className="text-lg font-semibold mb-4">Tareas por Prioridad</h3>
          <div className="h-[300px]">
            <Bar options={options} data={calculatePriorityDistribution()} />
          </div>
        </Card>
      </div>
    </div>
  );
}
