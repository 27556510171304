"use client";

import { useState } from "react";
import { UserList } from "./components/user-list";
import { Button } from "@/components/ui/button";
import { UserPlus } from "lucide-react";
import Navbar from "@/components/narbar/narbar";
import { ManagementTabs } from "./components/management-tabs";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import type { TeamMember } from "./types";

export function UsersPage() {
  const [users] = useState<TeamMember[]>([
    {
      id: "1",
      name: "John Doe",
      email: "john@example.com",
      role: "admin",
      joinedAt: new Date(2023, 0, 15),
    },
    {
      id: "2",
      name: "Jane Smith",
      email: "jane@example.com",
      role: "member",
      joinedAt: new Date(2023, 2, 20),
    },
  ]);
  const [userToDelete, setUserToDelete] = useState<string | null>(null);

  const handleDeleteUser = (userId: string) => {
    setUserToDelete(userId);
  };

  const confirmDeleteUser = () => {
    setUserToDelete(null);
  };

  return (
    <div className="min-h-screen bg-background">
      <Navbar />
      <div className="container mx-auto p-6 space-y-6">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
          <div className="space-y-1">
            <h1 className="text-2xl font-bold">Gestión de Organización</h1>
            <p className="text-muted-foreground">
              Administra los equipos y usuarios de tu organización
            </p>
          </div>
          <Button className="shrink-0">
            <UserPlus className="w-4 h-4 mr-2" />
            Invitar Usuario
          </Button>
        </div>

        <ManagementTabs />

        <div className="rounded-lg border bg-card">
          <div className="p-6">
            <UserList
              users={users}
              onRemoveUser={handleDeleteUser}
              onChangeRole={(id, role) => console.log("Change role", id, role)}
            />
          </div>
        </div>

        <AlertDialog
          open={!!userToDelete}
          onOpenChange={() => setUserToDelete(null)}
        >
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>¿Estás seguro?</AlertDialogTitle>
              <AlertDialogDescription>
                Esta acción eliminará al usuario de la organización y revocará
                su acceso a todos los equipos.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancelar</AlertDialogCancel>
              <AlertDialogAction
                onClick={confirmDeleteUser}
                className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
              >
                Eliminar
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    </div>
  );
}
