import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useNavigate } from "react-router-dom";

export function ManagementTabs() {
  const navigate = useNavigate();
  return (
    <Tabs value="teams" className="w-full">
      <TabsList className="grid w-full max-w-[400px] grid-cols-2">
        <TabsTrigger value="teams" onClick={() => navigate("/teams")}>
          Equipos
        </TabsTrigger>
        <TabsTrigger value="users" onClick={() => navigate("/teams/users")}>
          Usuarios
        </TabsTrigger>
      </TabsList>
    </Tabs>
  );
}
