/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Folder, RefreshCw, ChevronRight, Home } from "lucide-react";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import FileOptionsMenu from "./FileOptionsMenu";
import { formatFileSize } from "@/packages/utils/formatFileSize";
import { getFileIcon } from "@/packages/utils/extenciones";

interface Directory {
  directory_name: string;
  directory_path: string;
  _id: string;
}

interface FileListProps {
  files: any[];
  directories: Directory[];
  currentPath?: string;
  onDirectoryClick?: (path: string) => void;
  onRefresh?: () => void;
  onMoveToTrash?: (fileName: string) => void;
  onRestoreFromTrash?: (fileName: string) => void;
  isTrash?: boolean;
}

export default function FileList({
  files,
  directories,
  currentPath = "/",
  onDirectoryClick,
  onRestoreFromTrash,
  isTrash = false,
}: FileListProps) {
  const isImage = (fileName: string) => {
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
    const extension = fileName.split(".").pop()?.toLowerCase() || "";
    return imageExtensions.includes(extension);
  };

  const filteredFiles = files.filter((file) =>
    isTrash ? file.directory === "/trash" : file.directory === currentPath
  );

  const currentDirectories = directories.filter((dir) => {
    const parentPath = dir.directory_path.substring(
      0,
      dir.directory_path.lastIndexOf("/")
    );
    return (
      parentPath === currentPath ||
      (currentPath === "/" && dir.directory_path.split("/").length === 2)
    );
  });

  const pathParts = currentPath.split("/").filter(Boolean);

  return (
    <div className="space-y-4 sm:space-y-6 p-3 sm:p-6 bg-gray-50 rounded-lg shadow-sm">
      <nav className="flex flex-wrap items-center gap-2 text-sm text-gray-600 bg-white p-2 sm:p-3 rounded-md shadow-sm overflow-x-auto">
        <button
          onClick={() => onDirectoryClick && onDirectoryClick("/")}
          className="hover:text-primary transition-colors duration-200 flex items-center space-x-1 whitespace-nowrap"
        >
          <Home className="h-4 w-4" />
          <span>Home</span>
        </button>
        {pathParts.map((part, index) => (
          <React.Fragment key={index}>
            <ChevronRight className="h-4 w-4 text-gray-400 flex-shrink-0" />
            <button
              onClick={() =>
                onDirectoryClick &&
                onDirectoryClick("/" + pathParts.slice(0, index + 1).join("/"))
              }
              className="hover:text-primary transition-colors duration-200 whitespace-nowrap"
            >
              {part}
            </button>
          </React.Fragment>
        ))}
      </nav>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 sm:gap-4">
        {!isTrash &&
          currentDirectories.map((directory) => (
            <div
              key={directory._id}
              onClick={() =>
                onDirectoryClick && onDirectoryClick(directory.directory_path)
              }
              className="bg-white rounded-lg shadow-sm p-3 sm:p-4 flex items-center space-x-3 cursor-pointer hover:bg-gray-50 transition-colors duration-200 group"
            >
              <Folder className="h-8 w-8 sm:h-10 sm:w-10 text-primary group-hover:text-primary-dark transition-colors duration-200" />
              <div className="flex-1 min-w-0">
                <p className="text-sm font-medium text-gray-900 truncate group-hover:text-primary transition-colors duration-200">
                  {directory.directory_name}
                </p>
              </div>
            </div>
          ))}

        {filteredFiles.map((file) => (
          <div
            key={file.file_id}
            className={`bg-white rounded-lg shadow-md p-3 sm:p-4 flex items-center space-x-3 ${
              isTrash ? "opacity-70 hover:opacity-100" : ""
            } transition-all duration-200 border border-gray-200 hover:border-primary hover:shadow-lg`}
          >
            {isImage(file.file_name) ? (
              <Dialog>
                <DialogTrigger asChild>
                  <img
                    src={file.service_url}
                    alt={file.file_name}
                    className={`h-8 w-8 sm:h-10 sm:w-10 object-cover rounded cursor-pointer ${
                      isTrash ? "opacity-70 hover:opacity-100" : ""
                    } transition-opacity duration-200`}
                  />
                </DialogTrigger>
                <DialogContent className="sm:max-w-lg">
                  <img
                    src={file.service_url}
                    alt={file.file_name}
                    className="w-full h-auto rounded-lg shadow-lg"
                  />
                </DialogContent>
              </Dialog>
            ) : (
              getFileIcon(file.file_name, isTrash)
            )}
            <div className="flex-1 min-w-0">
              <p className="text-sm font-medium text-gray-900 truncate">
                {file.file_name}
              </p>
              <p className="text-xs text-gray-500">
                {isTrash
                  ? `Deleted: ${new Date(file.upload_date).toLocaleString()}`
                  : formatFileSize(file.file_size)}
              </p>
            </div>
            {isTrash ? (
              <Button
                variant="outline"
                size="sm"
                onClick={() =>
                  onRestoreFromTrash && onRestoreFromTrash(file.file_id)
                }
                className="flex items-center space-x-1 hover:bg-primary hover:text-white transition-colors duration-200"
              >
                <RefreshCw className="h-4 w-4" />
                <span>Restore</span>
              </Button>
            ) : (
              <FileOptionsMenu file={file} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
