"use client";

import * as React from "react";
import {
  ChevronLeft,
  ChevronRight,
  Filter,
  MapPin,
  Plus,
  SortAsc,
} from "lucide-react";
import {
  addDays,
  format,
  startOfMonth,
  eachDayOfInterval,
  endOfMonth,
  isSameMonth,
  isWithinInterval,
} from "date-fns";
import { es } from "date-fns/locale";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Board, Task } from "../types";

interface RoadmapViewProps {
  board: Board;
  onAddTask: (task: Partial<Task>) => void;
}

export default function RoadmapView({ board, onAddTask }: RoadmapViewProps) {
  const [currentDate, setCurrentDate] = React.useState(new Date());
  const [filter, setFilter] = React.useState("");

  const handleAddTask = () => {
    const newTask: Partial<Task> = {
      content: "New Task",
      startDate: format(currentDate, "yyyy-MM-dd"),
      endDate: format(addDays(currentDate, 7), "yyyy-MM-dd"),
    };
    onAddTask(newTask);
  };

  const allTasks = React.useMemo(
    () => Object.values(board.columns).flatMap((column) => column.tasks),
    [board.columns]
  );

  const filteredTasks = React.useMemo(
    () =>
      allTasks.filter((task) =>
        task.content.toLowerCase().includes(filter.toLowerCase())
      ),
    [allTasks, filter]
  );

  const monthStart = startOfMonth(currentDate);
  const monthEnd = endOfMonth(currentDate);
  const days = eachDayOfInterval({ start: monthStart, end: monthEnd });

  const navigateMonth = (direction: "prev" | "next") => {
    setCurrentDate((prev) => {
      if (direction === "prev") {
        return addDays(prev, -30);
      }
      return addDays(prev, 30);
    });
  };

  const getTaskPosition = (task: Task) => {
    if (!task.startDate || !task.endDate) {
      return null;
    }

    const startDate = new Date(task.startDate);
    const endDate = new Date(task.endDate);
    const dayWidth = 40; // Width of each day column

    if (
      !isSameMonth(startDate, currentDate) &&
      !isSameMonth(endDate, currentDate)
    ) {
      return null;
    }

    const taskInterval = { start: startDate, end: endDate };
    const visibleStartDate = isWithinInterval(monthStart, taskInterval)
      ? monthStart
      : startDate;
    const visibleEndDate = isWithinInterval(monthEnd, taskInterval)
      ? monthEnd
      : endDate;

    const startDayIndex = days.findIndex(
      (day) =>
        format(day, "yyyy-MM-dd") === format(visibleStartDate, "yyyy-MM-dd")
    );

    const endDayIndex = days.findIndex(
      (day) =>
        format(day, "yyyy-MM-dd") === format(visibleEndDate, "yyyy-MM-dd")
    );

    if (startDayIndex === -1 || endDayIndex === -1) return null;

    return {
      left: startDayIndex * dayWidth,
      width: (endDayIndex - startDayIndex + 1) * dayWidth,
    };
  };

  return (
    <div className="flex flex-col h-full bg-background">
      <div className="flex items-center gap-2 p-4 border-b">
        <div className="relative flex-1 max-w-md">
          <Filter className="absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground" />
          <Input
            placeholder="Filter by keyword or by field"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="pl-8"
          />
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" size="sm">
              <MapPin className="mr-2 h-4 w-4" />
              Markers
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem>Add marker</DropdownMenuItem>
            <DropdownMenuItem>Manage markers</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        <Button variant="outline" size="sm">
          <SortAsc className="mr-2 h-4 w-4" />
          Sort
        </Button>
        <Button variant="outline" size="sm">
          Month
        </Button>
        <Button variant="ghost" size="sm" onClick={() => navigateMonth("prev")}>
          <ChevronLeft className="h-4 w-4" />
        </Button>
        <Button variant="ghost" size="sm" onClick={() => navigateMonth("next")}>
          <ChevronRight className="h-4 w-4" />
        </Button>
      </div>

      <ScrollArea className="flex-1">
        <div className="relative">
          {/* Calendar Header */}
          <div className="sticky top-0 z-10 flex border-b bg-background">
            {days.map((day) => (
              <div
                key={day.toISOString()}
                className="flex-none w-[40px] border-r p-1 text-center text-sm"
              >
                <div className="font-medium">{format(day, "d")}</div>
                <div className="text-xs text-muted-foreground">
                  {format(day, "EEE", { locale: es })}
                </div>
              </div>
            ))}
          </div>

          {/* Tasks Timeline */}
          <div className="relative min-h-[200px]">
            {filteredTasks.map((task, taskIndex) => {
              const position = getTaskPosition(task);
              if (!position) return null;

              return (
                <div
                  key={task.id}
                  className="absolute h-8 my-2 rounded-md border bg-background shadow-sm"
                  style={{
                    left: position.left,
                    width: position.width,
                    top: taskIndex * 48,
                  }}
                >
                  <div
                    className="px-2 py-1 text-sm truncate"
                    style={{ backgroundColor: task.color }}
                  >
                    {task.content}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </ScrollArea>

      <div className="p-4 border-t">
        <Button onClick={handleAddTask}>
          <Plus className="mr-2 h-4 w-4" />
          Add item
        </Button>
      </div>
    </div>
  );
}
