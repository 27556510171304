import { BarChart2, ListTodo, Calendar } from "lucide-react";
import { cn } from "@/lib/utils";
import { Card } from "@/components/ui/card";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";

interface ProjectTabsProps {
  activeTab: string;
  onTabChange: (tabId: string) => void;
  className?: string;
}

export default function ProjectTabs({
  activeTab,
  onTabChange,
  className,
}: ProjectTabsProps) {
  const tabs = [
    {
      id: "metrics",
      label: "Métricas",
      icon: <BarChart2 className="w-4 h-4" />,
    },
    {
      id: "backlog",
      label: "Backlog",
      icon: <ListTodo className="w-4 h-4" />,
    },

    {
      id: "roadmap",
      label: "Roadmap",
      icon: <Calendar className="w-4 h-4" />,
    },
  ];

  return (
    <Card className={cn("border-b rounded-none", className)}>
      <Tabs value={activeTab} onValueChange={onTabChange} className="w-full">
        <TabsList className="w-full justify-start gap-2 rounded-none border-b bg-transparent p-0">
          {tabs.map((tab) => (
            <TabsTrigger
              key={tab.id}
              value={tab.id}
              className={cn(
                "rounded-none border-b-2 border-transparent pb-2 pt-2 data-[state=active]:border-primary",
                "gap-2 [&>svg]:h-4 [&>svg]:w-4"
              )}
            >
              {tab.icon}
              {tab.label}
            </TabsTrigger>
          ))}
        </TabsList>
      </Tabs>
    </Card>
  );
}
