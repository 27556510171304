import { useState, useEffect, useRef, RefObject, lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { Zap, Menu } from "lucide-react";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { motion, useScroll, useTransform } from "framer-motion";
import { Helmet } from "react-helmet";
import LanguageSwitcher from "@/components/LanguageSwitcher";

// Lazy load components
const FooterHome = lazy(() => import("./footer").then(module => ({ default: module.FooterHome })));
const FeaturesSection = lazy(() => import("./feature").then(module => ({ default: module.FeaturesSection })));
const PricingSeccion = lazy(() => import("./Pricing").then(module => ({ default: module.PricingSeccion })));
const SupportSeccion = lazy(() => import("./support").then(module => ({ default: module.SupportSeccion })));

// Loading fallback component
const LoadingFallback = () => (
  <div className="w-full h-32 flex items-center justify-center">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
  </div>
);

export default function Component() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [animateHeader, setAnimateHeader] = useState(false);

  const featuresRef = useRef<HTMLDivElement>(null);
  const pricingRef = useRef<HTMLDivElement>(null);
  const supportRef = useRef<HTMLDivElement>(null);
  const heroRef = useRef<HTMLDivElement>(null);

  const { scrollYProgress } = useScroll({
    target: heroRef,
    offset: ["start start", "end start"],
  });

  const opacity = useTransform(scrollYProgress, [0, 1], [1, 0]);
  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.8]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setAnimateHeader(scrollPosition > 50);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToSection = (ref: RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
      setIsOpen(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{t("home.seo.title")}</title>
        <meta name="description" content={t("home.seo.description")} />
        <meta name="keywords" content={t("home.seo.keywords")} />
        <link rel="preload" href="/assets/hero-image.webp" as="image" />
      </Helmet>

      <div className="flex flex-col min-h-screen bg-gradient-to-b from-white to-gray-50">
        <motion.header
          className={`fixed w-full top-0 z-50 transition-all ${
            animateHeader
              ? "bg-white/80 backdrop-blur-md shadow-sm py-2"
              : "bg-transparent py-4"
          }`}
          initial={{ y: -100 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center">
              <motion.div
                className="flex items-center"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Zap className="h-6 w-6 sm:h-8 sm:w-8 text-blue-600" />
                <span className="ml-2 text-lg sm:text-xl font-bold bg-gradient-to-r from-blue-600 to-green-400 bg-clip-text text-transparent">
                  ZapTrack
                </span>
              </motion.div>

              <Sheet open={isOpen} onOpenChange={setIsOpen}>
                <SheetTrigger asChild>
                  <Button variant="ghost" className="lg:hidden p-2 mr-2" size="icon">
                    <Menu className="h-5 w-5 sm:h-6 sm:w-6" />
                  </Button>
                </SheetTrigger>
                <SheetContent side="right" className="w-[300px] sm:w-[400px]">
                  <nav className="flex flex-col gap-4">
                    <Button
                      variant="ghost"
                      onClick={() => scrollToSection(featuresRef)}
                    >
                      {t("home.nav.features")}
                    </Button>
                    <Button
                      variant="ghost"
                      onClick={() => scrollToSection(pricingRef)}
                    >
                      {t("home.nav.pricing")}
                    </Button>
                    <Button
                      variant="ghost"
                      onClick={() => scrollToSection(supportRef)}
                    >
                      {t("home.nav.support")}
                    </Button>
                  </nav>
                  <div className="mt-auto space-y-4">
                    <div className="flex justify-center">
                      <LanguageSwitcher />
                    </div>
                    <Button variant="outline" className="w-full">
                      <a href="/login">{t("home.nav.login")}</a>
                    </Button>
                    <Button className="w-full bg-gradient-to-r from-blue-600 to-green-400 hover:from-blue-700 hover:to-green-500">
                      <a href="/register">{t("home.nav.register")}</a>
                    </Button>
                  </div>
                </SheetContent>
              </Sheet>

              <nav className="hidden lg:flex lg:gap-x-12">
                <motion.button
                  onClick={() => scrollToSection(featuresRef)}
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {t("home.nav.features")}
                </motion.button>
                <motion.button
                  onClick={() => scrollToSection(pricingRef)}
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {t("home.nav.pricing")}
                </motion.button>
                <motion.button
                  onClick={() => scrollToSection(supportRef)}
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {t("home.nav.support")}
                </motion.button>
              </nav>

              <div className="hidden lg:flex items-center gap-4">
                <LanguageSwitcher />
                <motion.a
                  href="/login"
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {t("home.nav.login")}
                </motion.a>
                <motion.a
                  href="/register"
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gradient-to-r from-blue-600 to-green-400 hover:from-blue-700 hover:to-green-500"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {t("home.nav.register")}
                </motion.a>
              </div>
            </div>
          </div>
        </motion.header>

        <main className="flex-grow pt-24">
          <motion.div
            ref={heroRef}
            className="relative min-h-screen flex items-center justify-center overflow-hidden"
            style={{ opacity, scale }}
          >
            <div className="absolute inset-0 z-0">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-50 to-green-50 opacity-70" />
              <div className="absolute inset-0 bg-[url('/grid.svg')] bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]" />
            </div>

            <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 sm:py-24 md:py-32 text-center">
              <motion.h1
                className="text-3xl sm:text-4xl md:text-5xl lg:text-7xl font-bold tracking-tight bg-gradient-to-r from-gray-900 to-gray-600 bg-clip-text text-transparent mb-4 sm:mb-8"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
              >
                {t("home.hero.title")}
              </motion.h1>
              <motion.p
                className="mt-4 sm:mt-6 text-base sm:text-lg md:text-xl leading-7 sm:leading-8 text-gray-600 max-w-2xl sm:max-w-3xl mx-auto px-2 sm:px-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
              >
                {t("home.hero.subtitle")}
              </motion.p>
              <motion.div
                className="mt-8 sm:mt-10 flex flex-col sm:flex-row items-center justify-center gap-4 sm:gap-x-6"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.6 }}
              >
                <Button
                  onClick={() => scrollToSection(featuresRef)}
                  className="w-full sm:w-auto rounded-full bg-gradient-to-r from-blue-600 to-green-400 hover:from-blue-700 hover:to-green-500 px-6 sm:px-8 py-4 sm:py-6 text-base sm:text-lg"
                >
                  {t("home.hero.getStarted")}
                </Button>
                <Button
                  variant="outline"
                  onClick={() => scrollToSection(supportRef)}
                  className="w-full sm:w-auto rounded-full px-6 sm:px-8 py-4 sm:py-6 text-base sm:text-lg"
                >
                  {t("home.hero.learnMore")}
                </Button>
              </motion.div>
            </div>
          </motion.div>

          <Suspense fallback={<LoadingFallback />}>
            <div ref={featuresRef}>
              <FeaturesSection />
            </div>
            <div ref={pricingRef}>
              <PricingSeccion />
            </div>
            <div ref={supportRef}>
              <SupportSeccion />
            </div>
          </Suspense>
        </main>

        <Suspense fallback={<LoadingFallback />}>
          <FooterHome />
        </Suspense>
      </div>
    </>
  );
}
