import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { format, parse } from "date-fns";
import { projectTemplates } from "../templates/project-templates";
import type { Board } from "../types";

interface NewProjectModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCreateProject: (projectData: Partial<Board>) => void;
}

export default function NewProjectModal({
  isOpen,
  onClose,
  onCreateProject,
}: NewProjectModalProps) {
  const [projectData, setProjectData] = useState<Partial<Board>>({
    title: "",
    description: "",
    scope: "",
    template: "custom",
    teamMembers: [],
  });
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [newTeamMember, setNewTeamMember] = useState("");
  const [, /* calendarKey */ setCalendarKey] = useState(0);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!(event.target as Element).closest(".react-calendar")) {
        setCalendarKey((prev) => prev + 1);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCreateProject = () => {
    if (projectData.title?.trim() !== "") {
      onCreateProject({
        ...projectData,
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
      });
      setProjectData({
        title: "",
        description: "",
        scope: "",
        template: "custom",
        teamMembers: [],
      });
      setStartDate(undefined);
      setEndDate(undefined);
      setNewTeamMember("");
      onClose();
    }
  };

  const handleAddTeamMember = () => {
    if (
      newTeamMember.trim() &&
      !projectData.teamMembers?.includes(newTeamMember)
    ) {
      setProjectData({
        ...projectData,
        teamMembers: [...(projectData.teamMembers || []), newTeamMember.trim()],
      });
      setNewTeamMember("");
    }
  };

  const handleRemoveTeamMember = (member: string) => {
    setProjectData({
      ...projectData,
      teamMembers: projectData.teamMembers?.filter((m) => m !== member) || [],
    });
  };

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const dateString = event.target.value;
    try {
      const date = parse(dateString, "yyyy-MM-dd", new Date());
      setStartDate(date);
    } catch {
      console.error("Invalid date format");
    }
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dateString = event.target.value;
    try {
      const date = parse(dateString, "yyyy-MM-dd", new Date());
      setEndDate(date);
    } catch {
      console.error("Invalid date format");
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Crear nuevo proyecto</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <label htmlFor="project-title" className="text-right">
              Título del proyecto *
            </label>
            <Input
              id="project-title"
              value={projectData.title}
              onChange={(e) =>
                setProjectData({ ...projectData, title: e.target.value })
              }
              className="col-span-3"
              required
            />
          </div>

          <div className="grid grid-cols-4 items-center gap-4">
            <label htmlFor="project-description" className="text-right">
              Descripción
            </label>
            <Textarea
              id="project-description"
              value={projectData.description}
              onChange={(e) =>
                setProjectData({ ...projectData, description: e.target.value })
              }
              className="col-span-3"
            />
          </div>

          <div className="grid grid-cols-4 items-center gap-4">
            <label htmlFor="project-scope" className="text-right">
              Alcance
            </label>
            <Textarea
              id="project-scope"
              value={projectData.scope}
              onChange={(e) =>
                setProjectData({ ...projectData, scope: e.target.value })
              }
              className="col-span-3"
            />
          </div>

          <div className="grid grid-cols-4 items-center gap-4">
            <label className="text-right">Plantilla</label>
            <Select
              value={projectData.template}
              onValueChange={(value) =>
                setProjectData({ ...projectData, template: value })
              }
            >
              <SelectTrigger className="col-span-3">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {projectTemplates.map((template) => (
                  <SelectItem key={template.id} value={template.id}>
                    {template.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="grid grid-cols-4 items-center gap-4">
            <label className="text-right">Miembros del equipo</label>
            <div className="col-span-3 space-y-2">
              <div className="flex gap-2">
                <Input
                  value={newTeamMember}
                  onChange={(e) => setNewTeamMember(e.target.value)}
                  placeholder="Agregar miembro del equipo"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleAddTeamMember();
                    }
                  }}
                />
                <Button
                  type="button"
                  onClick={handleAddTeamMember}
                  variant="secondary"
                >
                  Agregar
                </Button>
              </div>
              <div className="flex flex-wrap gap-2">
                {projectData.teamMembers?.map((member) => (
                  <div
                    key={member}
                    className="flex items-center gap-1 bg-secondary px-2 py-1 rounded-md"
                  >
                    <span>{member}</span>
                    <Button
                      type="button"
                      variant="ghost"
                      size="sm"
                      className="h-auto p-1"
                      onClick={() => handleRemoveTeamMember(member)}
                    >
                      ×
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="grid grid-cols-4 items-center gap-4">
            <label className="text-right">Fecha de inicio</label>
            <div className="col-span-3 flex gap-2">
              <Input
                type="date"
                value={startDate ? format(startDate, "yyyy-MM-dd") : ""}
                onChange={handleStartDateChange}
                className="w-full"
              />
            </div>
          </div>

          <div className="grid grid-cols-4 items-center gap-4">
            <label className="text-right">Fecha de fin</label>
            <div className="col-span-3 flex gap-2">
              <Input
                type="date"
                value={endDate ? format(endDate, "yyyy-MM-dd") : ""}
                onChange={handleEndDateChange}
                className="w-full"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end space-x-2">
          <Button variant="outline" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            onClick={handleCreateProject}
            disabled={!projectData.title?.trim()}
          >
            Crear proyecto
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
