import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { Team } from "../teams";

interface CreateTeamModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCreateTeam: (team: Omit<Team, "id" | "createdAt">) => void;
}

export function CreateTeamModal({
  isOpen,
  onClose,
  onCreateTeam,
}: CreateTeamModalProps) {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    storageLimit: 10, // Default 10GB
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onCreateTeam({
      ...formData,
      members: [],
    });
    setFormData({ name: "", description: "", storageLimit: 10 });
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Crear Nuevo Equipo</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="name">Nombre del Equipo</Label>
            <Input
              id="name"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              required
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="description">Descripción</Label>
            <Textarea
              id="description"
              value={formData.description}
              onChange={(e) =>
                setFormData({ ...formData, description: e.target.value })
              }
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="storage">Límite de Almacenamiento (GB)</Label>
            <Input
              id="storage"
              type="number"
              min="1"
              value={formData.storageLimit}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  storageLimit: parseInt(e.target.value),
                })
              }
              required
            />
          </div>
          <div className="flex justify-end space-x-2">
            <Button type="button" variant="outline" onClick={onClose}>
              Cancelar
            </Button>
            <Button type="submit">Crear Equipo</Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}
