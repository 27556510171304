import { TeamMember } from "../types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { MoreVertical, Shield, User, UserMinus, UserCog } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";

interface UserListProps {
  users: TeamMember[];
  onRemoveUser?: (userId: string) => void;
  onChangeRole?: (userId: string, role: "admin" | "member") => void;
}

export function UserList({ users, onRemoveUser, onChangeRole }: UserListProps) {
  if (users.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center py-12 px-4">
        <div className="h-32 w-32 mb-6 text-muted-foreground">
          <User size={128} />
        </div>
        <h3 className="text-lg font-semibold mb-2">No hay usuarios</h3>
        <p className="text-muted-foreground text-center max-w-sm mb-6">
          Invita a los miembros de tu organización para comenzar a colaborar.
        </p>
      </div>
    );
  }

  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Usuario</TableHead>
            <TableHead>Rol</TableHead>
            <TableHead>Fecha de ingreso</TableHead>
            <TableHead className="w-[50px]"></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell>
                <div className="flex items-center gap-3">
                  <Avatar>
                    <AvatarImage
                      src={`https://avatar.vercel.sh/${user.email}`}
                    />
                    <AvatarFallback>
                      {user.name
                        .split(" ")
                        .map((n) => n[0])
                        .join("")}
                    </AvatarFallback>
                  </Avatar>
                  <div>
                    <div className="font-medium">{user.name}</div>
                    <div className="text-sm text-muted-foreground">
                      {user.email}
                    </div>
                  </div>
                </div>
              </TableCell>
              <TableCell>
                <Badge
                  variant={user.role === "admin" ? "default" : "secondary"}
                >
                  <Shield className="mr-1 h-3 w-3" />
                  {user.role === "admin" ? "Administrador" : "Miembro"}
                </Badge>
              </TableCell>
              <TableCell>
                {new Date(user.joinedAt).toLocaleDateString()}
              </TableCell>
              <TableCell>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" className="h-8 w-8 p-0">
                      <MoreVertical className="h-4 w-4" />
                      <span className="sr-only">Abrir menú</span>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuItem
                      onClick={() =>
                        onChangeRole?.(
                          user.id,
                          user.role === "admin" ? "member" : "admin"
                        )
                      }
                    >
                      <UserCog className="mr-2 h-4 w-4" />
                      Cambiar a{" "}
                      {user.role === "admin" ? "Miembro" : "Administrador"}
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      className="text-destructive"
                      onClick={() => onRemoveUser?.(user.id)}
                    >
                      <UserMinus className="mr-2 h-4 w-4" />
                      Eliminar Usuario
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
