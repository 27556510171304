import React from "react";
import { Button } from "@/components/ui/button";
import {
  Zap,
  Grid,
  Folder,
  Calendar,
  MessageSquare,
  Users,
  Bell,
  Circle,
  Clock,
  Coffee,
  Minus,
  LogOut,
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from "@/components/ui/dropdown-menu";
import { Link, useNavigate } from "react-router-dom";
import { NavbarMovile } from "./narbarMovile";
import { Toaster } from "@/components/ui/toaster";
import { Badge } from "@/components/ui/badge";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useTranslation } from "react-i18next";

const userStatuses = [
  { value: "online", label: "En línea", icon: Circle, color: "text-green-500" },
  { value: "busy", label: "Ocupado", icon: Minus, color: "text-red-500" },
  {
    value: "meeting",
    label: "En reunión",
    icon: Clock,
    color: "text-yellow-500",
  },
  { value: "away", label: "Ausente", icon: Coffee, color: "text-gray-500" },
];

export default function Navbar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userStatus, setUserStatus] = React.useState("online");

  const handleNavigate = (nav: string) => {
    navigate(nav);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/login");
  };

  const modules = [
    {
      name: t("navigation.dashboard"),
      icon: Folder,
      path: "/dashboard",
      color: "bg-blue-500",
    },
    {
      name: t("navigation.board"),
      icon: Grid,
      path: "/board",
      color: "bg-green-500",
    },
    {
      name: t("navigation.teams"),
      icon: Users,
      path: "/teams",
      color: "bg-orange-500",
    },
    {
      name: t("navigation.calendar"),
      icon: Calendar,
      path: "/calendar",
      color: "bg-red-500",
    },
    {
      name: t("navigation.chat"),
      icon: MessageSquare,
      path: "/chat",
      color: "bg-indigo-500",
    },
  ];

  const StatusIcon =
    userStatuses.find((status) => status.value === userStatus)?.icon || Circle;

  return (
    <nav className="border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4">
          <div className="flex items-center">
            <Zap className="h-8 w-auto sm:h-10 text-yellow-400" />
            <a
              className="cursor-pointer"
              onClick={() => handleNavigate("/dashboard")}
            >
              <span className="ml-2 text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-green-400">
                ZapTrack
              </span>
            </a>
          </div>
          <nav className="hidden md:flex items-center space-x-6">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="ghost"
                  size="sm"
                  className="flex flex-col items-center"
                >
                  <Grid className="h-6 w-6 text-gray-600 hover:text-gray-900 transition-colors" />
                  <span className="text-xs mt-1">
                    {t("navigation.modules")}
                  </span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-64 p-4">
                <div className="grid grid-cols-2 gap-4">
                  {modules.map((module) => (
                    <DropdownMenuItem
                      key={module.name}
                      onSelect={() => handleNavigate(module.path)}
                      className="flex flex-col items-center justify-center p-2 hover:bg-gray-100 rounded-lg transition-all duration-200 transform hover:scale-105"
                    >
                      <div className={`${module.color} rounded-full p-2 mb-2`}>
                        <module.icon className="h-5 w-5 text-white" />
                      </div>
                      <span className="text-sm font-medium">{module.name}</span>
                    </DropdownMenuItem>
                  ))}
                </div>
              </DropdownMenuContent>
            </DropdownMenu>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="ghost"
                  size="sm"
                  className="flex flex-col items-center relative"
                >
                  <Bell className="h-6 w-6 text-gray-600 hover:text-gray-900 transition-colors" />
                  <Badge className="absolute -top-1 -right-1 px-1.5 py-0.5 text-xs bg-red-500 text-white">
                    3
                  </Badge>
                  <span className="text-xs mt-1">
                    {t("navigation.notifications")}
                  </span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-64">
                <DropdownMenuItem className="flex flex-col items-start">
                  <span className="font-medium">
                    {t("navigation.newTaskAssigned")}
                  </span>
                  <span className="text-xs text-gray-500">
                    {t("navigation.fiveMinutesAgo")}
                  </span>
                </DropdownMenuItem>
                <DropdownMenuItem className="flex flex-col items-start">
                  <span className="font-medium">
                    {t("navigation.meetingScheduled")}
                  </span>
                  <span className="text-xs text-gray-500">
                    {t("navigation.tomorrowAt10am")}
                  </span>
                </DropdownMenuItem>
                <DropdownMenuItem className="flex flex-col items-start">
                  <span className="font-medium">
                    {t("navigation.systemUpdate")}
                  </span>
                  <span className="text-xs text-gray-500">
                    {t("navigation.completed")}
                  </span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="sm" className="relative p-0">
                  <Avatar className="h-12 w-12 border-2 border-gray-300">
                    <AvatarImage
                      src="https://github.com/shadcn.png"
                      alt="@shadcn"
                    />
                    <AvatarFallback>CN</AvatarFallback>
                  </Avatar>
                  <StatusIcon
                    className={`absolute -bottom-1 -right-1 h-4 w-4 rounded-full bg-white p-[2px] ${
                      userStatuses.find((status) => status.value === userStatus)
                        ?.color
                    }`}
                  />
                  <span className="sr-only">{t("navigation.userMenu")}</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-56">
                <DropdownMenuItem>
                  <Link to="/profile" className="w-full">
                    {t("navigation.profile")}
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <Link to="/settings" className="w-full">
                    {t("navigation.settings")}
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuRadioGroup
                  value={userStatus}
                  onValueChange={setUserStatus}
                >
                  {userStatuses.map((status) => (
                    <DropdownMenuRadioItem
                      key={status.value}
                      value={status.value}
                    >
                      <status.icon className={`mr-2 h-4 w-4 ${status.color}`} />
                      <span>{status.label}</span>
                    </DropdownMenuRadioItem>
                  ))}
                </DropdownMenuRadioGroup>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={handleLogout}>
                  <LogOut className="mr-2 h-4 w-4" />
                  <span>{t("auth.logout")}</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </nav>
          <NavbarMovile />
        </div>
      </div>
      <Toaster />
    </nav>
  );
}
