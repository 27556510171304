import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Globe } from "lucide-react";

const LanguageSwitcher: React.FC = () => {
  const { i18n, t } = useTranslation();

  const toggleLanguage = () => {
    const newLang = i18n.language === "en" ? "es" : "en";
    i18n.changeLanguage(newLang);
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            onClick={toggleLanguage}
            variant="ghost"
            size="sm"
            className="flex items-center gap-2 text-gray-500 hover:text-gray-900 px-3"
          >
            <Globe className="h-4 w-4" />
            <span className="text-base">
              {i18n.language === "en" ? "🇪🇸" : "🇬🇧"}
            </span>
            <span className="text-sm font-medium">
              {i18n.language === "en" ? "Español" : "English"}
            </span>
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>{t("common.switchLanguage")}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default LanguageSwitcher;
