import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import {
  Zap,
  UserIcon,
  MailIcon,
  LockIcon,
  EyeIcon,
  EyeOffIcon,
  CalendarIcon,
  CreditCardIcon,
  ArrowLeft,
} from "lucide-react";
import { useRegister } from "./use_register";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function Register() {
  const { t } = useTranslation();
  const {
    email,
    password,
    confirmPassword,
    name,
    document_number,
    birthdate,
    gender,
    usageType,
    handleEmail,
    handlePassword,
    handleConfirmPassword,
    handleName,
    handleDocument_number,
    handleBirthdate,
    handleGender,
    handleUsageType,
    initRegister,
    loading,
  } = useRegister();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const result = await initRegister();
    if (result?.success) {
      // Aquí guardas los datos del usuario sin contraseña
      const userData = result.response;
      localStorage.setItem("user", JSON.stringify(userData));

      setShowAnimation(true);
      setTimeout(() => {
        setShowAnimation(false);
        navigate("/welcome", { replace: true });
      }, 2000);
    } else {
      console.log(result);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-blue-400 to-blue-600 p-4">
      <AnimatePresence>
        {showAnimation && (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
            transition={{ duration: 0.5 }}
            className="fixed inset-0 flex items-center justify-center bg-white z-50"
          >
            <div className="flex flex-col items-center">
              <Zap
                className="w-20 h-20 text-yellow-400 stroke-current"
                strokeWidth={2}
              />
              <motion.div
                initial={{ width: 0 }}
                animate={{ width: "100%" }}
                transition={{ duration: 1.5, delay: 0.5 }}
                className="h-2 bg-gradient-to-r from-blue-600 to-green-400 mt-4"
                style={{ width: "200px" }}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <Card className="w-full max-w-md bg-white/90 backdrop-blur-sm">
        <form onSubmit={handleSubmit} className="space-y-4">
          <CardHeader className="space-y-1">
            <Button
              variant="ghost"
              size="icon"
              onClick={() => navigate("/")}
              className="absolute left-0 top-0 text-blue-600 hover:text-blue-800"
            >
              <ArrowLeft className="h-6 w-6" />
              <span className="sr-only">{t("auth.backToHome")}</span>
            </Button>
            <CardTitle className="text-3xl font-bold text-center text-blue-900 pt-2">
              <div className="flex items-center justify-center space-x-2">
                <Zap
                  className="w-10 h-10 text-yellow-400 stroke-current"
                  strokeWidth={3}
                />
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-green-400">
                  ZapTrack
                </span>
              </div>
            </CardTitle>
            <CardDescription className="text-center text-blue-700">
              {t("auth.register")}
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="grid gap-2">
              <Label htmlFor="name">{t("auth.name")}</Label>
              <div className="relative">
                <UserIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-500" />
                <Input
                  id="name"
                  value={name}
                  onChange={(e) => handleName(e.target.value)}
                  className="pl-10 border-blue-300 focus:border-green-400 focus:ring-green-400"
                  required
                />
              </div>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="email">{t("auth.email")}</Label>
              <div className="relative">
                <MailIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-500" />
                <Input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => handleEmail(e.target.value)}
                  className="pl-10 border-blue-300 focus:border-green-400 focus:ring-green-400"
                  required
                />
              </div>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="document_number">{t("auth.document_number")}</Label>
              <div className="relative">
                <CreditCardIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-500" />
                <Input
                  id="document_number"
                  value={document_number}
                  onChange={(e) => handleDocument_number(e.target.value)}
                  className="pl-10 border-blue-300 focus:border-green-400 focus:ring-green-400"
                  required
                />
              </div>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="birthdate">{t("auth.birthdate")}</Label>
              <div className="relative">
                <CalendarIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-500" />
                <Input
                  id="birthdate"
                  type="date"
                  value={birthdate}
                  onChange={(e) => handleBirthdate(e.target.value)}
                  className="pl-10 border-blue-300 focus:border-green-400 focus:ring-green-400"
                  required
                />
              </div>
            </div>

            <div className="grid gap-2">
              <Label>{t("auth.gender")}</Label>
              <RadioGroup
                value={gender}
                onValueChange={handleGender}
                className="flex space-x-4"
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="male" id="male" />
                  <Label htmlFor="male">{t("auth.male")}</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="female" id="female" />
                  <Label htmlFor="female">{t("auth.female")}</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="other" id="other" />
                  <Label htmlFor="other">{t("auth.other")}</Label>
                </div>
              </RadioGroup>
            </div>

            <div className="grid gap-2">
              <Label>{t("auth.usageType")}</Label>
              <Select value={usageType} onValueChange={handleUsageType}>
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="personal">{t("auth.personal")}</SelectItem>
                  <SelectItem value="business">{t("auth.business")}</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="password">{t("auth.password")}</Label>
              <div className="relative">
                <LockIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-500" />
                <Input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => handlePassword(e.target.value)}
                  className="pl-10 pr-10 border-blue-300 focus:border-green-400 focus:ring-green-400"
                  required
                />
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  className="absolute right-0 top-0 h-full px-3 text-blue-500 hover:text-blue-600"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <EyeOffIcon className="h-4 w-4" />
                  ) : (
                    <EyeIcon className="h-4 w-4" />
                  )}
                  <span className="sr-only">
                    {showPassword ? t("auth.hidePassword") : t("auth.showPassword")}
                  </span>
                </Button>
              </div>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="confirmPassword">{t("auth.confirmPassword")}</Label>
              <div className="relative">
                <LockIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-500" />
                <Input
                  id="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => handleConfirmPassword(e.target.value)}
                  className="pl-10 pr-10 border-blue-300 focus:border-green-400 focus:ring-green-400"
                  required
                />
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  className="absolute right-0 top-0 h-full px-3 text-blue-500 hover:text-blue-600"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <EyeOffIcon className="h-4 w-4" />
                  ) : (
                    <EyeIcon className="h-4 w-4" />
                  )}
                  <span className="sr-only">
                    {showConfirmPassword ? t("auth.hidePassword") : t("auth.showPassword")}
                  </span>
                </Button>
              </div>
            </div>

            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="terms"
                  checked={acceptTerms}
                  onCheckedChange={(checked: boolean) => setAcceptTerms(checked)}
                />
                <Label htmlFor="terms" className="text-sm">
                  {t("auth.termsAndConditions")}
                </Label>
              </div>
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="privacy"
                  checked={acceptPrivacy}
                  onCheckedChange={(checked: boolean) => setAcceptPrivacy(checked)}
                />
                <Label htmlFor="privacy" className="text-sm">
                  {t("auth.privacyPolicy")}
                </Label>
              </div>
            </div>

            <Button
              type="submit"
              className="w-full bg-gradient-to-r from-blue-600 to-green-400 hover:from-blue-700 hover:to-green-500 text-white"
              disabled={loading || showAnimation || !acceptTerms || !acceptPrivacy}
            >
              {loading ? t("auth.registering") : t("auth.signUp")}
            </Button>
          </CardContent>
          <CardContent className="space-y-4">
            <div className="grid gap-2">
              <Label htmlFor="password">{t("auth.password")}</Label>
              <div className="relative">
                <LockIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-500" />
                <Input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => handlePassword(e.target.value)}
                  className="pl-10 border-blue-300 focus:border-green-400 focus:ring-green-400"
                  required
                />
              </div>
            </div>
          </CardContent>
          <CardFooter className="flex flex-col space-y-2">
            <div className="text-center text-sm text-blue-700">
              {t("auth.alreadyHaveAccount")}{" "}
              <a href="/login" className="text-green-500 hover:underline">
                {t("auth.signIn")}
              </a>
            </div>
          </CardFooter>
        </form>
      </Card>
    </div>
  );
}
