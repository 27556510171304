import { useState } from "react";
import { TeamList } from "./components/TeamList";
import { CreateTeamModal } from "./components/CreateTeamModal";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import Navbar from "@/components/narbar/narbar";
import { ManagementTabs } from "./components/management-tabs";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Team } from "./types";

export function TeamsPage() {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [teams, setTeams] = useState<Team[]>([]);
  const [teamToDelete, setTeamToDelete] = useState<string | null>(null);

  const handleCreateTeam = (newTeam: Omit<Team, "id" | "createdAt">) => {
    const team: Team = {
      ...newTeam,
      id: Math.random().toString(36).substr(2, 9),
      createdAt: new Date(),
    };
    setTeams([...teams, team]);
    setIsCreateModalOpen(false);
  };

  const handleDeleteTeam = (teamId: string) => {
    setTeamToDelete(teamId);
  };

  const confirmDeleteTeam = () => {
    if (teamToDelete) {
      setTeams(teams.filter((team) => team.id !== teamToDelete));
      setTeamToDelete(null);
    }
  };

  return (
    <div className="min-h-screen bg-background">
      <Navbar />
      <div className="container mx-auto p-6 space-y-6">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
          <div className="space-y-1">
            <h1 className="text-2xl font-bold">Gestión de Organización</h1>
            <p className="text-muted-foreground">
              Administra los equipos y usuarios de tu organización
            </p>
          </div>
          <Button
            onClick={() => setIsCreateModalOpen(true)}
            className="shrink-0"
          >
            <Plus className="w-4 h-4 mr-2" />
            Crear Equipo
          </Button>
        </div>

        <ManagementTabs />

        <TeamList
          teams={teams}
          onDeleteTeam={handleDeleteTeam}
          onManageMembers={(id) => console.log("Manage members", id)}
          onManageSettings={(id) => console.log("Manage settings", id)}
        />

        <CreateTeamModal
          isOpen={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
          onCreateTeam={handleCreateTeam}
        />

        <AlertDialog
          open={!!teamToDelete}
          onOpenChange={() => setTeamToDelete(null)}
        >
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>¿Estás seguro?</AlertDialogTitle>
              <AlertDialogDescription>
                Esta acción no se puede deshacer. Se eliminará el equipo y todos
                sus datos permanentemente.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancelar</AlertDialogCancel>
              <AlertDialogAction
                onClick={confirmDeleteTeam}
                className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
              >
                Eliminar
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    </div>
  );
}

export type { Team };
