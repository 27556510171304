import { Team } from "../types";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import {
  Users,
  HardDrive,
  Settings,
  MoreVertical,
  Trash2,
  UserPlus,
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Progress } from "@/components/ui/progress";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

interface TeamListProps {
  teams: Team[];
  onDeleteTeam?: (teamId: string) => void;
  onManageMembers?: (teamId: string) => void;
  onManageSettings?: (teamId: string) => void;
}

export function TeamList({
  teams,
  onDeleteTeam,
  onManageMembers,
  onManageSettings,
}: TeamListProps) {
  if (teams.length === 0) {
    return (
      <div className="col-span-full flex flex-col items-center justify-center py-12 px-4">
        <div className="h-32 w-32 mb-6 text-muted-foreground">
          <Users size={128} />
        </div>
        <h3 className="text-lg font-semibold mb-2">No hay equipos creados</h3>
        <p className="text-muted-foreground text-center max-w-sm mb-6">
          Crea tu primer equipo para comenzar a colaborar con otros miembros de
          tu organización.
        </p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {teams.map((team) => (
        <Card key={team.id} className="group">
          <CardHeader className="flex flex-row items-start justify-between space-y-0 pb-2">
            <div className="space-y-1">
              <CardTitle>{team.name}</CardTitle>
              <CardDescription className="line-clamp-2">
                {team.description}
              </CardDescription>
            </div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="ghost"
                  className="h-8 w-8 p-0 opacity-0 group-hover:opacity-100"
                >
                  <MoreVertical className="h-4 w-4" />
                  <span className="sr-only">Abrir menú</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem onClick={() => onManageMembers?.(team.id)}>
                  <UserPlus className="mr-2 h-4 w-4" />
                  Gestionar Miembros
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => onManageSettings?.(team.id)}>
                  <Settings className="mr-2 h-4 w-4" />
                  Configuración
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="text-destructive"
                  onClick={() => onDeleteTeam?.(team.id)}
                >
                  <Trash2 className="mr-2 h-4 w-4" />
                  Eliminar Equipo
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              <div className="flex items-center gap-2">
                <Users className="w-4 h-4 text-muted-foreground" />
                <span>{team.members.length} miembros</span>
              </div>
              <div className="space-y-2">
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center gap-2">
                    <HardDrive className="w-4 h-4 text-muted-foreground" />
                    <span>Almacenamiento</span>
                  </div>
                  <span className="text-muted-foreground">
                    {team.storageLimit} GB
                  </span>
                </div>
                <Progress value={33} className="h-2" />
              </div>
              <div className="flex -space-x-2">
                {team.members.slice(0, 5).map((member) => (
                  <Avatar
                    key={member.id}
                    className="border-2 border-background"
                  >
                    <AvatarImage
                      src={`https://avatar.vercel.sh/${member.email}`}
                    />
                    <AvatarFallback>
                      {member.name
                        .split(" ")
                        .map((n) => n[0])
                        .join("")}
                    </AvatarFallback>
                  </Avatar>
                ))}
                {team.members.length > 5 && (
                  <div className="flex h-8 w-8 items-center justify-center rounded-full border-2 border-background bg-muted text-xs">
                    +{team.members.length - 5}
                  </div>
                )}
              </div>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
}
