import { useTranslation } from "react-i18next";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useState, useEffect } from "react";
import Navbar from "@/components/narbar/narbar";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";

export default function OrganizationSettings() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // Check if user is admin
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    if (user.isAdmin) {
      toast({
        variant: "destructive",
        title: t("common.error"),
        description: t("common.unauthorized"),
      });
      navigate("/dashboard");
    } else {
      setIsAdmin(true);
    }
  }, [navigate, t, toast]);

  const [orgSettings, setOrgSettings] = useState({
    name: "",
    description: "",
    email: "",
    phone: "",
    address: "",
    timezone: "UTC",
    defaultLanguage: "en",
    logo: "",
  });

  const [features, setFeatures] = useState({
    chat: true,
    calendar: true,
    notes: true,
    board: true,
    hr: true,
    accounting: true,
  });

  const [security, setSecurity] = useState({
    twoFactorRequired: false,
    passwordMinLength: "8",
    passwordExpiration: "90",
    sessionTimeout: "30",
  });

  if (!isAdmin) {
    return null;
  }

  return (
    <div>
      <Navbar />
      <div className="container mx-auto py-10">
        <h1 className="text-3xl font-bold mb-8">
          {t("settings.organizationTitle")}
        </h1>

        <Tabs defaultValue="general" className="space-y-4">
          <TabsList>
            <TabsTrigger value="general">{t("settings.general")}</TabsTrigger>
            <TabsTrigger value="features">{t("settings.features")}</TabsTrigger>
            <TabsTrigger value="security">{t("settings.security")}</TabsTrigger>
          </TabsList>

          <TabsContent value="general">
            <Card>
              <CardHeader>
                <CardTitle>{t("settings.organizationInfo")}</CardTitle>
                <CardDescription>
                  {t("settings.organizationInfoDescription")}
                </CardDescription>
              </CardHeader>
              <CardContent className="space-y-6">
                <div className="space-y-2">
                  <Label>{t("settings.organizationName")}</Label>
                  <Input
                    value={orgSettings.name}
                    onChange={(e) =>
                      setOrgSettings({ ...orgSettings, name: e.target.value })
                    }
                  />
                </div>

                <div className="space-y-2">
                  <Label>{t("settings.organizationDescription")}</Label>
                  <Textarea
                    value={orgSettings.description}
                    onChange={(e) =>
                      setOrgSettings({
                        ...orgSettings,
                        description: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="space-y-2">
                  <Label>{t("settings.organizationEmail")}</Label>
                  <Input
                    type="email"
                    value={orgSettings.email}
                    onChange={(e) =>
                      setOrgSettings({ ...orgSettings, email: e.target.value })
                    }
                  />
                </div>

                <div className="space-y-2">
                  <Label>{t("settings.organizationPhone")}</Label>
                  <Input
                    value={orgSettings.phone}
                    onChange={(e) =>
                      setOrgSettings({ ...orgSettings, phone: e.target.value })
                    }
                  />
                </div>

                <div className="space-y-2">
                  <Label>{t("settings.organizationAddress")}</Label>
                  <Textarea
                    value={orgSettings.address}
                    onChange={(e) =>
                      setOrgSettings({
                        ...orgSettings,
                        address: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="space-y-2">
                  <Label>{t("settings.defaultLanguage")}</Label>
                  <Select
                    value={orgSettings.defaultLanguage}
                    onValueChange={(value) =>
                      setOrgSettings({ ...orgSettings, defaultLanguage: value })
                    }
                  >
                    <SelectTrigger>
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="en">English</SelectItem>
                      <SelectItem value="es">Español</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <div className="space-y-2">
                  <Label>{t("settings.timezone")}</Label>
                  <Select
                    value={orgSettings.timezone}
                    onValueChange={(value) =>
                      setOrgSettings({ ...orgSettings, timezone: value })
                    }
                  >
                    <SelectTrigger>
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="UTC">UTC</SelectItem>
                      <SelectItem value="America/New_York">EST</SelectItem>
                      <SelectItem value="America/Chicago">CST</SelectItem>
                      <SelectItem value="America/Denver">MST</SelectItem>
                      <SelectItem value="America/Los_Angeles">PST</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <Button>{t("common.save")}</Button>
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="features">
            <Card>
              <CardHeader>
                <CardTitle>{t("settings.moduleAccess")}</CardTitle>
                <CardDescription>
                  {t("settings.moduleAccessDescription")}
                </CardDescription>
              </CardHeader>
              <CardContent className="space-y-6">
                <div className="flex items-center justify-between">
                  <Label htmlFor="chat-module">
                    {t("settings.chatModule")}
                  </Label>
                  <Switch
                    id="chat-module"
                    checked={features.chat}
                    onCheckedChange={(checked) =>
                      setFeatures({ ...features, chat: checked })
                    }
                  />
                </div>

                <div className="flex items-center justify-between">
                  <Label htmlFor="calendar-module">
                    {t("settings.calendarModule")}
                  </Label>
                  <Switch
                    id="calendar-module"
                    checked={features.calendar}
                    onCheckedChange={(checked) =>
                      setFeatures({ ...features, calendar: checked })
                    }
                  />
                </div>

                <div className="flex items-center justify-between">
                  <Label htmlFor="notes-module">
                    {t("settings.notesModule")}
                  </Label>
                  <Switch
                    id="notes-module"
                    checked={features.notes}
                    onCheckedChange={(checked) =>
                      setFeatures({ ...features, notes: checked })
                    }
                  />
                </div>

                <div className="flex items-center justify-between">
                  <Label htmlFor="board-module">
                    {t("settings.boardModule")}
                  </Label>
                  <Switch
                    id="board-module"
                    checked={features.board}
                    onCheckedChange={(checked) =>
                      setFeatures({ ...features, board: checked })
                    }
                  />
                </div>

                <div className="flex items-center justify-between">
                  <Label htmlFor="hr-module">{t("settings.hrModule")}</Label>
                  <Switch
                    id="hr-module"
                    checked={features.hr}
                    onCheckedChange={(checked) =>
                      setFeatures({ ...features, hr: checked })
                    }
                  />
                </div>

                <div className="flex items-center justify-between">
                  <Label htmlFor="accounting-module">
                    {t("settings.accountingModule")}
                  </Label>
                  <Switch
                    id="accounting-module"
                    checked={features.accounting}
                    onCheckedChange={(checked) =>
                      setFeatures({ ...features, accounting: checked })
                    }
                  />
                </div>

                <Button>{t("common.save")}</Button>
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="security">
            <Card>
              <CardHeader>
                <CardTitle>{t("settings.securitySettings")}</CardTitle>
                <CardDescription>
                  {t("settings.securitySettingsDescription")}
                </CardDescription>
              </CardHeader>
              <CardContent className="space-y-6">
                <div className="flex items-center justify-between">
                  <div>
                    <Label>{t("settings.requireTwoFactor")}</Label>
                    <p className="text-sm text-gray-500">
                      {t("settings.requireTwoFactorDescription")}
                    </p>
                  </div>
                  <Switch
                    checked={security.twoFactorRequired}
                    onCheckedChange={(checked) =>
                      setSecurity({ ...security, twoFactorRequired: checked })
                    }
                  />
                </div>

                <div className="space-y-2">
                  <Label>{t("settings.passwordMinLength")}</Label>
                  <Select
                    value={security.passwordMinLength}
                    onValueChange={(value) =>
                      setSecurity({ ...security, passwordMinLength: value })
                    }
                  >
                    <SelectTrigger>
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="8">
                        8 {t("settings.characters")}
                      </SelectItem>
                      <SelectItem value="10">
                        10 {t("settings.characters")}
                      </SelectItem>
                      <SelectItem value="12">
                        12 {t("settings.characters")}
                      </SelectItem>
                      <SelectItem value="14">
                        14 {t("settings.characters")}
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <div className="space-y-2">
                  <Label>{t("settings.passwordExpiration")}</Label>
                  <Select
                    value={security.passwordExpiration}
                    onValueChange={(value) =>
                      setSecurity({ ...security, passwordExpiration: value })
                    }
                  >
                    <SelectTrigger>
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="30">
                        30 {t("settings.days")}
                      </SelectItem>
                      <SelectItem value="60">
                        60 {t("settings.days")}
                      </SelectItem>
                      <SelectItem value="90">
                        90 {t("settings.days")}
                      </SelectItem>
                      <SelectItem value="180">
                        180 {t("settings.days")}
                      </SelectItem>
                      <SelectItem value="never">
                        {t("settings.never")}
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <div className="space-y-2">
                  <Label>{t("settings.sessionTimeout")}</Label>
                  <Select
                    value={security.sessionTimeout}
                    onValueChange={(value) =>
                      setSecurity({ ...security, sessionTimeout: value })
                    }
                  >
                    <SelectTrigger>
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="15">
                        15 {t("settings.minutes")}
                      </SelectItem>
                      <SelectItem value="30">
                        30 {t("settings.minutes")}
                      </SelectItem>
                      <SelectItem value="60">
                        60 {t("settings.minutes")}
                      </SelectItem>
                      <SelectItem value="120">
                        120 {t("settings.minutes")}
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <Button>{t("common.save")}</Button>
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
}
