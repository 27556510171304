import { useState } from "react";
import { DragDropContext, DropResult } from "@hello-pangea/dnd";
import Navbar from "@/components/narbar/narbar";
import { Board, Task, Column } from "./types";
import { initialBoards } from "./use_board";
import { projectTemplates } from "./templates/project-templates";
import ProjectSidebar from "./components/ProjectSidebar";
import BoardContent from "./components/BoardContent";
import NewProjectModal from "./components/NewProjectModal";
import ProjectTabs from "./components/ProjectTabs";
import TableView from "./components/TableView";
import RoadmapView from "./components/RoadmapView";
import MetricsView from "./components/MetricsView";

export default function BoardManager() {
  const [boards, setBoards] = useState<Board[]>(initialBoards);
  const [activeBoard, setActiveBoard] = useState<Board>(boards[0]);
  const [isNewProjectModalOpen, setIsNewProjectModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("metrics");

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === "column") {
      const newColumnOrder = Array.from(activeBoard.columnOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);

      setActiveBoard((prevBoard) => ({
        ...prevBoard,
        columnOrder: newColumnOrder,
      }));
      return;
    }

    const startColumn = activeBoard.columns[source.droppableId];
    const finishColumn = activeBoard.columns[destination.droppableId];

    if (startColumn === finishColumn) {
      const newTasks = Array.from(startColumn.tasks);
      newTasks.splice(source.index, 1);
      newTasks.splice(destination.index, 0, startColumn.tasks[source.index]);

      const newColumn = {
        ...startColumn,
        tasks: newTasks,
      };

      setActiveBoard((prevBoard) => ({
        ...prevBoard,
        columns: {
          ...prevBoard.columns,
          [newColumn.id]: newColumn,
        },
      }));
    } else {
      const startTasks = Array.from(startColumn.tasks);
      const [movedTask] = startTasks.splice(source.index, 1);
      const newStartColumn = {
        ...startColumn,
        tasks: startTasks,
      };

      const finishTasks = Array.from(finishColumn.tasks);
      finishTasks.splice(destination.index, 0, movedTask);
      const newFinishColumn = {
        ...finishColumn,
        tasks: finishTasks,
      };

      setActiveBoard((prevBoard) => ({
        ...prevBoard,
        columns: {
          ...prevBoard.columns,
          [newStartColumn.id]: newStartColumn,
          [newFinishColumn.id]: newFinishColumn,
        },
      }));
    }
  };

  const addNewBoard = (projectData: Partial<Board>) => {
    const selectedTemplate = projectTemplates.find(
      (t) => t.id === projectData.template
    );

    const columns: { [key: string]: Column } = {};
    const columnOrder: string[] = [];

    if (selectedTemplate) {
      selectedTemplate.columns.forEach((col) => {
        const columnId = col.id;
        columns[columnId] = {
          id: columnId,
          title: col.title,
          tasks: [],
        };
        columnOrder.push(columnId);
      });
    } else {
      const defaultColumns = [
        { id: "todo", title: "Por Hacer" },
        { id: "in-progress", title: "En Progreso" },
        { id: "done", title: "Completado" },
      ];

      defaultColumns.forEach((col) => {
        columns[col.id] = {
          id: col.id,
          title: col.title,
          tasks: [],
        };
        columnOrder.push(col.id);
      });
    }

    const newBoard: Board = {
      id: `board-${Date.now()}`,
      title: projectData.title || "Nuevo Proyecto",
      description: projectData.description,
      type: projectData.type || "personal",
      scope: projectData.scope,
      startDate: projectData.startDate,
      endDate: projectData.endDate,
      template: projectData.template,
      teamMembers: projectData.teamMembers,
      columns,
      columnOrder,
    };

    setBoards([...boards, newBoard]);
    setActiveBoard(newBoard);
  };

  const updateBoard = (updatedBoard: Board) => {
    setBoards(
      boards.map((board) =>
        board.id === updatedBoard.id ? updatedBoard : board
      )
    );
    if (activeBoard.id === updatedBoard.id) {
      setActiveBoard(updatedBoard);
    }
  };

  const deleteBoard = (boardId: string) => {
    setBoards(boards.filter((board) => board.id !== boardId));
    if (activeBoard.id === boardId) {
      setActiveBoard(boards[0]);
    }
  };

  /* const handleTabChange = (tabId: string) => {
    setActiveTab(tabId);
  }; */

  const addNewTask = (taskData: Partial<Task>) => {
    const newTask: Task = {
      id: `task-${Date.now()}`,
      content: taskData.content || "New Task",
      startDate: taskData.startDate || new Date().toISOString().split("T")[0],
      endDate: taskData.endDate || new Date().toISOString().split("T")[0],
      description: taskData.description || "",
      assignee: taskData.assignee || "",
      color: taskData.color || "#ffffff",
      tags: taskData.tags || [],
    };

    const firstColumnId = activeBoard.columnOrder[0];
    const updatedColumn = {
      ...activeBoard.columns[firstColumnId],
      tasks: [...activeBoard.columns[firstColumnId].tasks, newTask],
    };

    const updatedBoard = {
      ...activeBoard,
      columns: {
        ...activeBoard.columns,
        [firstColumnId]: updatedColumn,
      },
    };

    updateBoard(updatedBoard);
  };

  const handleAddTask = (taskData: Partial<Task>) => {
    addNewTask(taskData);
  };

  return (
    <div className="flex h-screen flex-col">
      <Navbar />
      <div className="flex flex-1 overflow-hidden">
        <ProjectSidebar
          boards={boards}
          activeBoard={activeBoard}
          setActiveBoard={setActiveBoard}
          onNewProject={() => setIsNewProjectModalOpen(true)}
          onUpdateBoard={updateBoard}
          onDeleteBoard={deleteBoard}
        />
        <div className="flex-1 flex flex-col min-w-0">
          <ProjectTabs activeTab={activeTab} onTabChange={setActiveTab} />
          <div className="flex-1 overflow-auto">
            {activeTab === "metrics" && <MetricsView board={activeBoard} />}
            {activeTab === "backlog" && (
              <DragDropContext onDragEnd={onDragEnd}>
                <BoardContent
                  activeBoard={activeBoard}
                  setActiveBoard={setActiveBoard}
                />
              </DragDropContext>
            )}
            {activeTab === "table" && <TableView board={activeBoard} />}
            {activeTab === "roadmap" && (
              <RoadmapView board={activeBoard} onAddTask={handleAddTask} />
            )}
          </div>
        </div>
      </div>
      <NewProjectModal
        isOpen={isNewProjectModalOpen}
        onClose={() => setIsNewProjectModalOpen(false)}
        onCreateProject={addNewBoard}
      />
    </div>
  );
}
