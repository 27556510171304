/* eslint-disable @typescript-eslint/no-explicit-any */
export interface ProjectTemplate {
  id: string;
  name: string;
  description: string;
  columns: {
    id: string;
    title: string;
    tasks: any[];
  }[];
}

export const projectTemplates: ProjectTemplate[] = [
  {
    id: "software-development",
    name: "Desarrollo de Software",
    description: "Plantilla para proyectos de desarrollo de software",
    columns: [
      { id: "backlog", title: "Backlog", tasks: [] },
      { id: "design", title: "Diseño", tasks: [] },
      { id: "in-progress", title: "En Desarrollo", tasks: [] },
      { id: "testing", title: "Pruebas", tasks: [] },
      { id: "done", title: "Completado", tasks: [] },
    ],
  },
  {
    id: "mobile-app",
    name: "Aplicación Móvil",
    description: "Plantilla para desarrollo de aplicaciones móviles",
    columns: [
      { id: "planning", title: "Planificación", tasks: [] },
      { id: "ui-design", title: "Diseño UI/UX", tasks: [] },
      { id: "development", title: "Desarrollo", tasks: [] },
      { id: "qa", title: "Control de Calidad", tasks: [] },
      { id: "store-submission", title: "Publicación", tasks: [] },
    ],
  },
  {
    id: "architecture",
    name: "Proyecto de Arquitectura",
    description: "Plantilla para proyectos de arquitectura",
    columns: [
      { id: "concept", title: "Concepto", tasks: [] },
      { id: "design", title: "Diseño", tasks: [] },
      { id: "documentation", title: "Documentación", tasks: [] },
      { id: "review", title: "Revisión", tasks: [] },
      { id: "approved", title: "Aprobado", tasks: [] },
    ],
  },
  {
    id: "custom",
    name: "Proyecto Personalizado",
    description: "Crea tu propia estructura de proyecto",
    columns: [
      { id: "todo", title: "Por Hacer", tasks: [] },
      { id: "in-progress", title: "En Progreso", tasks: [] },
      { id: "done", title: "Completado", tasks: [] },
    ],
  },
];
