import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Edit, X } from "lucide-react";
import { Task, Tag } from "../types";

interface TaskEditDialogProps {
  task: Task;
  onUpdateTask: (updatedTask: Task) => void;
  onDeleteTask: () => void;
}

export default function TaskEditDialog({
  task,
  onUpdateTask,
  onDeleteTask,
}: TaskEditDialogProps) {
  const [editedTask, setEditedTask] = useState<Task>(task);
  const [newTagName, setNewTagName] = useState("");

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setEditedTask((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddTag = () => {
    if (newTagName.trim() !== "") {
      const newTag: Tag = {
        id: `tag-${Date.now()}`,
        name: newTagName,
        color: "#" + Math.floor(Math.random() * 16777215).toString(16),
      };
      setEditedTask((prev) => ({
        ...prev,
        tags: [...(prev.tags || []), newTag],
      }));
      setNewTagName("");
    }
  };

  const handleRemoveTag = (tagId: string) => {
    setEditedTask((prev) => ({
      ...prev,
      tags: (prev.tags || []).filter((tag) => tag.id !== tagId),
    }));
  };

  const handleSave = () => {
    onUpdateTask(editedTask);
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="ghost" size="sm">
          <Edit className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{task.content}</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <label htmlFor="content" className="text-right">
              Contenido
            </label>
            <Input
              id="content"
              name="content"
              value={editedTask.content}
              onChange={handleInputChange}
              className="col-span-3"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <label htmlFor="start-date" className="text-right">
              Fecha de inicio
            </label>
            <Input
              id="start-date"
              name="startDate"
              type="date"
              value={editedTask.startDate}
              onChange={handleInputChange}
              className="col-span-3"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <label htmlFor="end-date" className="text-right">
              Fecha de fin
            </label>
            <Input
              id="end-date"
              name="endDate"
              type="date"
              value={editedTask.endDate}
              onChange={handleInputChange}
              className="col-span-3"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <label htmlFor="description" className="text-right">
              Descripción
            </label>
            <Textarea
              id="description"
              name="description"
              value={editedTask.description}
              onChange={handleInputChange}
              className="col-span-3"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <label htmlFor="assignee" className="text-right">
              Asignado a
            </label>
            <Input
              id="assignee"
              name="assignee"
              value={editedTask.assignee}
              onChange={handleInputChange}
              className="col-span-3"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <label htmlFor="task-color" className="text-right">
              Color de la tarea
            </label>
            <Input
              id="task-color"
              name="color"
              type="color"
              value={editedTask.color}
              onChange={handleInputChange}
              className="col-span-3"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <label htmlFor="new-tag" className="text-right">
              Nueva etiqueta
            </label>
            <Input
              id="new-tag"
              value={newTagName}
              onChange={(e) => setNewTagName(e.target.value)}
              placeholder="Nombre de la etiqueta"
              className="col-span-2"
            />
            <Button onClick={handleAddTag}>Añadir</Button>
          </div>
          <div className="flex flex-wrap gap-2">
            {(editedTask.tags || []).map((tag) => (
              <div
                key={tag.id}
                className="flex items-center gap-1 px-2 py-1 rounded-full text-sm"
                style={{
                  backgroundColor: tag.color,
                }}
              >
                {tag.name}
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => handleRemoveTag(tag.id)}
                >
                  <X className="h-3 w-3" />
                </Button>
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-between">
          <Button onClick={onDeleteTask} variant="destructive">
            Eliminar tarea
          </Button>
          <Button onClick={handleSave}>Guardar cambios</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
