import { Board } from "../types";
import ProjectMetrics from "./ProjectMetrics";
import ProjectCharts from "./ProjectCharts";

interface MetricsViewProps {
  board: Board;
}

export default function MetricsView({ board }: MetricsViewProps) {
  return (
    <div className="container mx-auto p-6">
      <h2 className="text-2xl font-bold mb-6">Métricas del Proyecto</h2>
      <ProjectMetrics board={board} />
      <ProjectCharts board={board} />
    </div>
  );
}
