import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from "@/components/ui/toaster";
import './i18n';
import { Suspense } from "react";
import Login from "@/pages/auth/login";
import Register from "@/pages/auth/register";
import Home from "@/pages/home/home";
import Dashboard from "@/pages/dashboard/dashboard";
import Board from "@/pages/board/board";
import CalendarView from "@/pages/calendar/calendar";
import ChatView from "@/pages/chat/chat";
import NotesView from "@/pages/notes/notes";
import Activities from "@/pages/activitie/activitie";
import PersonnelManagement from "@/pages/RRHH/PersonnelManagement";
import Recruitment from "@/pages/RRHH/JobOpening";
import Training from "@/pages/RRHH/Training";
import PurchaseAndSales from "@/pages/accounting/purchase_and_sale";
import Inventoy from "@/pages/accounting/inventory";
import Taxes from "@/pages/accounting/taxes";
import FinancialReports from "@/pages/accounting/financial_reports";
import UserProfilePage from "@/pages/user/profile";
import ProtectedRoute from "./routes/ProtectedRoute";
import Privacy from "@/pages/legal/privacy";
import Terms from "@/pages/legal/terms";
import Welcome from "@/pages/auth/welcome/welcome";
import AccountingModule from "@/pages/accounting";
import HRModule from "@/pages/RRHH";
import { TeamsPage } from "@/pages/teams/teams";
import { UsersPage } from "./pages/teams/users";
/* import { ThemeProvider } from "@/components/theme-provider"; */
import Settings from "@/pages/settings/settings";

function App() {
  return (
    <Suspense fallback="Loading...">
      <Router>
        <div className="min-h-screen">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/privacy" element={<Privacy />}></Route>
            <Route path="/terms" element={<Terms />}></Route>
            <Route
              path="/welcome"
              element={
                <ProtectedRoute>
                  <Welcome />
                </ProtectedRoute>
              }
            ></Route>
            <Route path="/" element={<Home />} />
            <Route
              path="/board"
              element={
                <ProtectedRoute>
                  <Board />
                </ProtectedRoute>
              }
            />
            <Route
              path="/calendar"
              element={
                <ProtectedRoute>
                  <CalendarView />
                </ProtectedRoute>
              }
            />
            <Route
              path="/chat"
              element={
                <ProtectedRoute>
                  <ChatView />
                </ProtectedRoute>
              }
            />
            <Route
              path="/notes"
              element={
                <ProtectedRoute>
                  <NotesView />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <UserProfilePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/activities"
              element={
                <ProtectedRoute>
                  <Activities />
                </ProtectedRoute>
              }
            />
            <Route
              path="/rrhh"
              element={
                <ProtectedRoute>
                  <HRModule />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teams"
              element={
                <ProtectedRoute>
                  <TeamsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teams/users"
              element={
                <ProtectedRoute>
                  <UsersPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/rrhh/personal"
              element={
                <ProtectedRoute>
                  <PersonnelManagement />
                </ProtectedRoute>
              }
            />
            <Route
              path="/rrhh/reclutamiento"
              element={
                <ProtectedRoute>
                  <Recruitment />
                </ProtectedRoute>
              }
            />
            <Route
              path="/rrhh/capacitacion"
              element={
                <ProtectedRoute>
                  <Training />
                </ProtectedRoute>
              }
            />
            <Route
              path="/contabilidad"
              element={
                <ProtectedRoute>
                  <AccountingModule />
                </ProtectedRoute>
              }
            />
            <Route
              path="/contabilidad/inventario"
              element={
                <ProtectedRoute>
                  <Inventoy />
                </ProtectedRoute>
              }
            />
            <Route
              path="/contabilidad/informes"
              element={
                <ProtectedRoute>
                  <FinancialReports />
                </ProtectedRoute>
              }
            />
            <Route
              path="/contabilidad/facturacion"
              element={<PurchaseAndSales />}
            />
            <Route path="/contabilidad/impuestos" element={<Taxes />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
          </Routes>
          <Toaster />
        </div>
      </Router>
    </Suspense>
  );
}

export default App;
